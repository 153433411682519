<template>
<!--  <div class="alert-deck">-->
<!--    <Alerts :alerts="alert" v-for="alert in initData.alerts" />-->
<!--  </div>-->
<!--  <Loader></Loader>-->
  <Head :contents="activeContent" :language="language"></Head>
  <Header v-if="currentRouteName!=='lookAround'" :initData="initData" :contents="activeContent" :language="language"></Header>
  <router-view :initData="initData" :contents="activeContent" :language="language"/>
  <Footer v-if="currentRouteName!=='lookAround'" :initData="initData" :contents="activeContent" :language="language"></Footer>
</template>

<script>
import {reactive} from "vue"
import Alerts from "@/components/Alerts.vue"
import Loader from "@/components/Loader.vue"
import Head from "@/components/Head.vue"
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import {CookieAgree} from "@/classes/cookieAgree.js"

export default {
    name: "App",
    props: ['serverData'],
    components: {
        Footer,
        Head,
        Header,
        Loader,
        Alerts
    },
    data() {
        const initData = reactive(this.serverData);
        console.log(this.serverData)

        new CookieAgree()

        return {
          initData
        }
    },
    computed: {
        language(){
            return this.serverData.language
        },
        activeContent() {
            let userLanguage = this.language
            if (userLanguage === null) {
                return []
            } else {
                return this.serverData.contents[userLanguage]
            }
        },
        currentRouteName() {
          let routeName = this.$route.name
          if (routeName === undefined) {
            return "mainPage"
          } else {
            return routeName.split("_")[0]
          }
        }
    }
}
</script>