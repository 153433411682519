import {createApp} from "vue"
import App from "./App.vue"
import router from "./router"

import axios from "axios"

import "bootstrap"
import "./assets/style.scss"
import "./assets/custom.css"
import "./assets/cookieAgree.css"

if(isLocalhost){
    //GET FROM API VUE_APP_API_PATH
    const apiUrl = process.env.VUE_APP_API_PATH+'/api/v1/development/serverData?t='+process.env.VUE_APP_API_TOKEN
    //FETCH CONTENT AND UPDATE SERVER DATA
    fetchContentJson(apiUrl).then((data) => {
        serverData = data
    })
    //TIMEOUT FOR DEVELOPMENT
    setTimeout(function(){
        const app = createApp(App,{serverData: serverData})

        axios.defaults.baseURL = process.env.VUE_APP_API_PATH
        axios.defaults.withCredentials = true
        app.config.globalProperties.$axios = axios
        axios.defaults.headers.common = {
            "Authorization": `Bearer ${localStorage.getItem("OITH_sessionHash")}`
        };
        //DYNAMIC ROUTES
        dynamicRoutes()
        app.use(router, axios)
        app.mount("#app")
    }, 1000)
}else{
    serverData = JSON.parse(atob(serverData))
    const app = createApp(App,{serverData: serverData})

    axios.defaults.baseURL = process.env.VUE_APP_API_PATH
    axios.defaults.withCredentials = true
    app.config.globalProperties.$axios = axios
    axios.defaults.headers.common = {
        "Authorization": `Bearer ${localStorage.getItem("OITH_sessionHash")}`
    };
    //DYNAMIC ROUTES
    dynamicRoutes()
    app.use(router, axios)
    app.mount("#app")
}


async function fetchContentJson(apiUrl){
    const response = await fetch(apiUrl)
    const serverData = await response.json()
    console.log(serverData.data)
    return serverData.data
}

function dynamicRoutes(){
    const routeData = serverData.routes
    for(let i=0;i<routeData.length;i++){
        const paths = routeData[i]['paths']
        const name = routeData[i]['name']
        //LOOP BY KEY AND VALUE
        for (const [key, value] of Object.entries(paths)) {
            const language = key
            const path = value
            const languageName = name+'_'+language
            router.addRoute(
                {
                    path: path,
                    name: languageName,
                    component: () => import('./views/'+routeData[i]['component']+'.vue'),
                    props: {'pageId' : name}
                }
            )
        }

    }
}