<template>
  <header :class="{'subpage': currentRouteName !== 'mainPage'}">
    <!-- Desktop Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
      <div class="container">
        <div class="d-flex align-items-center">
          <router-link :to="{name: 'mainPage_'+language}" class="nav-link" href="#">
            <img src="@/assets/images/logo.svg" alt="Logo" class="img-fluid" width="100" height="100">
          </router-link>
        </div>
        <div class="">
          <a href="https://competitions.ecosim.hu/otp-bank-it-hackathon-2024" target="_blank" rel="noreferrer" class="btn btn-primary m-2">{{content.menu.item10}}</a>
          <router-link :to="{name: 'lookAround_'+language}" class="btn btn-primary">{{content.menu.item11}}</router-link>
        </div>
      </div>
    </nav>

    <!-- Mobile Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-white d-lg-none justify-content-center align-items-center mx-2">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mobileNav" aria-controls="mobileNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="container">
        <div class="collapse navbar-collapse" id="mobileNav">
          <ul class="navbar-nav">
            <!-- Mobile Navigation Links -->
            <li class="nav-item">
              <router-link :to="{name: 'competition_'+language}" class="nav-link">{{content.menu.item1}}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'timing_'+language}" class="nav-link">{{content.menu.item2}}</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{content.menu.item3}}
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item"
                     href="https://karrier.otpbank.hu/job/Budapest-Adattárház-fejlesztő-Data-Engineer-1131/978283201/"
                     target="_blank">
                    {{content.menu.item3_1}}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item"
                     href="https://karrier.otpbank.hu/job/Budapest-Senior-Frontend-Developer-1131/934936901/"
                     target="_blank">
                    {{content.menu.item3_2}}
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'participants_'+language}" class="nav-link">{{content.menu.item4}}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'results_'+language}" class="nav-link">{{content.menu.item5}}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'partners_'+language}" class="nav-link">{{content.menu.item6}}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'competition2023_'+language}" class="nav-link">{{ content.menu.item7 }}</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'contact_'+language}" class="nav-link">{{content.menu.item8}}</router-link>
            </li>
            <!-- Add more mobile navigation links as needed -->
          </ul>
        </div>
      </div>
    </nav>

    <!-- Desktop Navbar -->
    <nav class="nav navbar navbar-expand-lg navbar-light bg-white justify-content-center align-items-center d-none d-lg-block">
      <div class="container">
        <ul class="navbar-nav mx-auto justify-content-between">
          <li class="nav-item">
            <router-link :to="{name: 'competition_'+language}" class="nav-link">{{content.menu.item1}}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'timing_'+language}" class="nav-link">{{content.menu.item2}}</router-link>
          </li>
          <li class="nav-item">
            <a
                class="nav-link"
               href="https://karrier.otpbank.hu/go/Informatika_digitalizacio/8942001/"
               target="_blank">
              {{content.menu.item3}}
            </a>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'participants_'+language}" class="nav-link">{{content.menu.item4}}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'results_'+language}" class="nav-link">{{content.menu.item5}}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'partners_'+language}" class="nav-link">{{content.menu.item6}}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'competition2023_'+language}" class="nav-link">{{ content.menu.item7 }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name: 'contact_'+language}" class="nav-link">{{content.menu.item8}}</router-link>
          </li>
          <!-- Add more desktop navigation links as needed -->
        </ul>
      </div>
    </nav>

    <div class="header-bottom container mt-4 mb-4">
      <div class="row">
        <div class="col-md-8">
          <h1 class="header-title display-4 text-white mt-3">{{content.subHeader.title}}</h1>
          <div class="header-subtitle" v-html="content.subHeader.txt"></div>
          <a href="https://competitions.ecosim.hu/otp-bank-it-hackathon-2024" target="_blank" rel="noreferrer" class="btn-reg btn btn-secondary">{{content.subHeader.button}}</a>
        </div>
      </div>
    </div>
  </header>
</template>



<script>
export default {
  name: "Header",
  props: ["initData", "contents","language"],
  computed: {
    currentRouteName() {
      let routeName = this.$route.name
      if (routeName === undefined) {
        return "mainPage"
      } else {
        return routeName.split("_")[0]
      }
    },
    content(){
      return this.contents.header
    }
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Squad';
  src: url('@/assets/fonts/Squad-Regular.woff2') format('woff2'),
  url('@/assets/fonts/Squad-Regular.woff') format('woff'),
  url('@/assets/fonts/Squad-Regular.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

header {
  background-image: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.1) 100%), url("@/assets/images/header_3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5rem 0;
}

@media (max-width: 767px) {
  header {
    background-position: 30% center;
  }
}

.header-title {
  font-family: 'Squad', sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
}

.header-subtitle {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.container {
  padding: 0;
}

.nav {
  margin: 0 auto;
  width: 100%;
  border-radius: 30px;
  max-width: 1064px;
}

.nav-item {
  padding: 0 0.5rem;
}

.nav-link {
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: black;
}

header.subpage {
  background-image: url("@/assets/images/background.png");
}

header.subpage .header-bottom {
  display: none;
}

header.subpage {
  padding-bottom: 5rem;
}

a.active-link {
  color: var(--otp-main)
}

.navbar {
  border-radius: 32px;
}

@media (max-width: 1024px) {
  .header-title,
  .header-subtitle,
  .btn-reg{
    margin-left: 10px; /* Adjust the margin as needed */
  }
}

</style>
